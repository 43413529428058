* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul,
li {
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 3.125rem #fff inset !important;
}

/* ---------- Form Input ---------- */
.form-control,
.form-select {
  font-size: 14px !important;
}

.form-control:focus,
.form-select:focus {
  box-shadow: none !important;
  border-color: #65b2eb;
}

.form-label {
  font-size: 14px;
  font-weight: 600;
  color: black;
}

.form-control:disabled {
  opacity: 0.7 !important;
  cursor: not-allowed;
}

.tableScrollNone::-webkit-scrollbar {
  display: none;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1.4px solid #e75126 !important;
}

.Mui-selected {
  background-color: #fcf1eb !important;
}
